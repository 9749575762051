<template>
  <div class="consumables">
    <BaseDialog
      :title="ruleForm.id ? '修改透析分组' : '新增透析分组'"
      :isshow="visible"
      @handleShow="
        (val) => {
          visible = val
        }
      "
      width="900px"
      top="10%"
    >
      <div class="consumables-search">
        <el-form
          :model="ruleForm"
          ref="ruleFormRef"
          label-width="110px"
          label-position="right"
          :inline="true"
          :rules="Rules.ADDDIALYSIS"
          class="demo-ruleForm col-333"
        >
          <el-form-item label="所属院区：" prop="parent_id">
            <el-select v-model="ruleForm.parent_id" placeholder="请选择">
              <el-option
                v-for="item in hospitalList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分组名称：" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="分组属性：" prop="dict_infect_flag">
            <el-select
              v-model="ruleForm.dict_infect_flag"
              placeholder="保存之后不能修改"
            >
              <el-option
                v-for="item in dictList[168000000]"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="addr" label="备注：">
            <el-input
              v-model="ruleForm.description"
              type="textarea"
              :rows="2"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="savePatient"
            :loading="saveLoad"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineExpose,
  defineProps,
  defineEmits,
  onMounted,
  nextTick,
} from 'vue'
import service from '@/utils/request'
import BaseDialog from '@/components/Dialog/index.vue'
import { ElMessage } from 'element-plus'
import Rule from '@/utils/rule'

defineProps({
  dictList: {
    type: Object,
  },
})
const userInfo = localStorage.getItem('ms_userData')
const ruleForm = ref({})
ruleForm.value.parent_id = userInfo.hospital
const visible = ref(false)
const hospitalList = ref([])
const ruleFormRef = ref(null)
const Rules = reactive(Rule)

onMounted(() => {
  getHospitalList()
})
const initData = (item) => {
  visible.value = true
  if (item) {
    ruleForm.value = item
  } else {
    ruleForm.value = {
      parent_id: userInfo.hospital,
    }
    if (hospitalList.value.length > 0 && !ruleForm.value.parent_id) {
      ruleForm.value.parent_id = hospitalList.value[0].id
    }
  }
  nextTick(() => {
    ruleFormRef.value.clearValidate()
  })
}

const getHospitalList = async () => {
  let res = await service.post('/api/hospital/list', {
    parent_id: 0,
  })
  if (res.code === 0) {
    hospitalList.value = res.data
    if (!ruleForm.value.parent_id) {
      ruleForm.value.parent_id = res.data[0].id
    }
  }
}

// 保存
const saveLoad = ref(false)
const em = defineEmits(['refreshDataList'])
const savePatient = async () => {
  ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      let url = ''
      if (ruleForm.value.id) {
        url = '/api/hospital/edit'
      } else {
        url = '/api/hospital/create'
      }
      saveLoad.value = true
      let res = await service.post(url, ruleForm.value)
      saveLoad.value = false
      if (res.code === 0) {
        ElMessage.success(res.msg)
        visible.value = false
        em('refreshDataList')
      }
    }
  })
}

defineExpose({
  initData,
})
</script>

<style scoped lang="scss">
.consumables-search {
  margin-top: 16px;
  .el-form-item {
    width: 47%;
    margin-right: 6%;
    &:nth-child(2n) {
      margin-right: 0;
    }
    &.addr {
      width: 100%;
      margin-right: 0;
    }
  }
}
</style>
