<template>
  <div class="bedBox fatherHeight">
    <div class="bedList-serach flex-between">
      <div class="serach-left">
        <el-form
          :inline="true"
          :model="tableSearch"
          ref="searchForm"
          class="demo-form-inline w-144"
        >
          <el-select
            class="hospital"
            v-model="tableSearch.parent_id"
            @change="getDataList"
            placeholder="请选择"
          >
            <el-option
              v-for="item in hospitalList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="tableSearch.type"
            clearable
            @change="getDataList"
            placeholder="请选择"
          >
            <el-option
              v-for="item in dictList[168000000]"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form>
      </div>
      <div class="serach-right">
        <el-button class="color-main" @click="addBed" type="primary">
          <i class="fa fa-plus-circle"></i>新增
        </el-button>
      </div>
    </div>
    <div class="table" ref="tableBoxRef">
      <el-table
        v-loading="dataListLoading"
        :data="tableData"
        class="table-ellipsis"
        size="small"
        border
      >
        <el-table-column fixed prop="hospital.name" label="院区" width="120" />
        <el-table-column prop="name" label="分组名称" />
        <el-table-column label="分组属性">
          <template #default="scope">
            <el-tag v-if="scope.row.dict_infect_flag == '168100000'">
              正常
            </el-tag>
            <el-tag v-else type="danger">阳性</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="床位数量">
          <template #default="scope">
            <div v-if="scope.row.bed">{{ scope.row.bed.length }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" />
        <el-table-column fixed="right" label="操作" width="100">
          <template #default="scope">
            <el-button type="text" size="small" @click="modParient(scope.row)">
              <i class="txIons modify"></i>
            </el-button>
            <el-button type="text" size="small" @click="delParient(scope.row)">
              <i class="txIons del"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="pagination-box">
        <el-pagination
          :currentPage="pageData.currentPage"
          :page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div> -->
    </div>
    <Add ref="addRef" :dictList="dictList" @refreshDataList="getDataList"></Add>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, defineExpose, nextTick } from 'vue'
import service from '@/utils/request'
import { gitDictList } from '@/utils/tool'
import Add from './add.vue'
import { ElMessage, ElMessageBox } from 'element-plus'

const userInfo = localStorage.getItem('ms_userData')
const dataListLoading = ref(false)
const istable = ref(true)
const pageData = reactive({
  currentPage: 1,
  pageSize: 10,
  totalSum: 0,
})
const tableSearch = reactive({
  spital_area_id: userInfo.hospital,
  refresh: '1',
})
const tableData = ref([])
const tableBoxRef = ref(null)
const tableHei = ref(0)
const dictList = ref({})

onMounted(async () => {
  getDict()
  await getHospitalList()
})
const getDict = async () => {
  dictList.value = await gitDictList(['168000000'])
}
const initData = () => {
  istable.value = false
  nextTick(() => {
    tableHei.value = tableBoxRef.value.clientHeight
    istable.value = true
  })
  handleSearch()
}
defineExpose({
  initData,
})
const handleSearch = () => {
  pageData.currentPage = 1
  getDataList()
}
// const handleSizeChange = (val) => {
//   pageData.pageSize = val
//   getDataList()
// }
// const handleCurrentChange = (val) => {
//   pageData.currentPage = val
//   getDataList()
// }
const getDataList = async () => {
  // tableSearch.current = pageData.currentPage
  // tableSearch.size = pageData.pageSize
  dataListLoading.value = true
  let res = await service.post('/api/hospital/list', tableSearch)
  dataListLoading.value = false
  if (res.code === 0) {
    tableData.value = res.data
    // pageData.totalSum = res.data.total
  }
}

const hospitalList = ref([])
const getHospitalList = async () => {
  let res = await service.post('/api/hospital/list', {
    parent_id: 0,
  })
  if (res.code === 0) {
    hospitalList.value = res.data
    if (!tableSearch.parent_id) {
      tableSearch.parent_id = res.data[0].id
    }
  }
}
const addRef = ref(null)
const addBed = () => {
  addRef.value.initData()
}
const modParient = (item) => {
  addRef.value.initData(item)
}
const delParient = (item) => {
  ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(async () => {
      let res = await service.post('/api/hospital/delete', {
        id: item.id,
      })
      if (res.code === 0) {
        ElMessage.success(res.msg)
        getDataList()
      }
    })
    .catch(() => {})
}
</script>

<style scoped lang="scss">
.bedList-serach {
  padding-top: 2px;
}
.table {
  height: calc(100% - 46px);
}
.hospital {
  margin-right: 10px;
}
</style>
