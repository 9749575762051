/**
 * @Rule 页面所有表单校验配置
 * @Name 命名要大写，多个单词用下划线隔开
 */

// import validator from './validator'
const Rule = {
  LOGIN: {
    username: [{ required: true, trigger: 'blur', message: '请输入用户名' }],
    password: [{ required: true, trigger: 'blur', message: '请输入密码' }],
  },
  DIALYSIS: {
    dict_dialysis_type: [
      { required: true, trigger: 'blur', message: '请选择透析方式' },
    ],
    dry_weight: [{ required: true, trigger: 'blur', message: '请输入干体重' }],
  },
  VASCULARASSESS: {
    dict_tonglu_type: [
      { required: true, trigger: 'blur', message: '请选择通路类型' },
    ],
    dict_tonglu_position: [
      { required: true, trigger: 'blur', message: '请选择建立部位' },
    ],
    dict_intervene_type: [
      { required: true, trigger: 'blur', message: '请选择介入方式' },
    ],
  },
  AddConsent:{
    name: [
      { required: true, trigger: 'blur', message: '请输入名称' },
    ],
    sort: [
      { required: true, trigger: 'blur', message: '请输入排序' },
    ],
    path: [
      { required: true, trigger: 'blur', message: '请选择文件' },
    ],
  },
  ASSESSIMG: {
    upload_time: [
      { required: true, trigger: 'blur', message: '请选择拍照日期' },
    ],
    upload_doctor_id: [
      { required: true, trigger: 'blur', message: '请选择操作医生' },
    ],
    url: [{ required: true, trigger: 'blur', message: '请选择图片' }],
  },
  LONGLERM: {
    create_doctor_id: [
      { required: true, trigger: 'blur', message: '请选择开嘱医生' },
    ],
    start_time: [
      { required: true, trigger: 'blur', message: '请选择开嘱时间' },
    ],
    use_time: [
      { required: true, trigger: 'blur', message: '请选择使用时间：' },
    ],
    dict_drug: [{ required: true, trigger: 'blur', message: '请输入药品名称' }],
    dosage: [{ required: true, trigger: 'blur', message: '请输入单次剂量' }],
    dict_dosage_unit: [
      { required: true, trigger: 'blur', message: '请选择单位' },
    ],
    dict_use_type: [
      { required: true, trigger: 'blur', message: '请选择用药途径' },
    ],
    time: [{ required: true, trigger: 'blur', message: '请输入每次用药间隔' }],
  },
  SETUP: {
    group_name: [{ required: true, trigger: 'blur', message: '请输入名称' }],
  },
  ESTIMATE: {
    dict_position: [{ required: true, trigger: 'blur', message: '请输入位置' }],
  },
  SUMMARY: {
    jingmai_num: [
      { required: true, trigger: 'blur', message: '请选择静脉穿刺次数' },
    ],
    dongmai_num: [
      { required: true, trigger: 'blur', message: '请选择动脉穿刺次数' },
    ],
    internal: [
      { required: true, trigger: 'blur', message: '请选择内瘘针滑脱' },
    ],
  },
  DISINFECTRECORD: {
    hospital_area_id: [
      { required: true, trigger: 'blur', message: '请选择院区' },
    ],
    bed_no: [{ required: true, trigger: 'blur', message: '请选择床位号' }],
    machine_no: [
      { required: true, trigger: 'blur', message: '请输入透析机编号' },
    ],
    record_time: [{ required: true, trigger: 'blur', message: '请选择日期' }],
  },
  COMPUTRER: {
    do_id: [{ required: true, trigger: 'blur', message: '请选择上机者' }],
  },
  ADDBED: {
    bed_no: [{ required: true, trigger: 'blur', message: '请输入床位号' }],
    hospital_area_id: [
      { required: true, trigger: 'blur', message: '请选择院区' },
    ],
    bed_area_id: [{ required: true, trigger: 'blur', message: '请选择分组' }],
  },
  ADDDIALYSIS: {
    parent_id: [{ required: true, trigger: 'blur', message: '请选择所属院区' }],
    name: [{ required: true, trigger: 'blur', message: '请输入分组名称' }],
    dict_infect_flag: [
      { required: true, trigger: 'blur', message: '请选择分组属性' },
    ],
  },
  ADDDOPERATION: {
    dict_machine_type: [
      { required: true, trigger: 'blur', message: '请选择设备类型' },
    ],
    machine_no: [
      { required: true, trigger: 'blur', message: '请输入设备编号' },
    ],
    machine_name: [
      { required: true, trigger: 'blur', message: '请输入设备名称' },
    ],
    infect_flag: [
      { required: true, trigger: 'blur', message: '请选择感染标记' },
    ],
    dict_treat_model: [
      { required: true, trigger: 'blur', message: '请选择治疗特征' },
    ],
  },
  ADDMENU: {
    name: [{ required: true, trigger: 'blur', message: '请输入菜单名称' }],
    component: [{ required: true, trigger: 'blur', message: '请输入组件名称' }],
    key: [{ required: true, trigger: 'blur', message: '请输入key' }],
  },
  ADDROIE: {
    key: [{ required: true, trigger: 'blur', message: '请输入唯一识别码' }],
    role_name: [{ required: true, trigger: 'blur', message: '请输入角色名称' }],
    role_type: [{ required: true, trigger: 'blur', message: '请选择角色类别' }],
    is_use: [{ required: true, trigger: 'blur', message: '请选择状态' }],
  },
  ADDTemp: {
    name: [{ required: true, trigger: 'blur', message: '请输入模板名称' }],
  },
}

export default Rule
